/* eslint-disable */
import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import PrivateRoute from 'PrivateRoute';
import withTokenControl from '../withTokenControl';

// render - dashboard
const MANAGEMENTINDEX = Loadable(lazy(() => import('Screens/Management/Pages/Index')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

const YardimPage = Loadable(lazy(() => import('pages/extra-pages/denemepage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/StokRaporları/StokRaporları')));
const Color = Loadable(lazy(() => import('pages/components-overview/TarihRaporları/TarihRaporları')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/OzelRapor/OzelRapor')));
const AuthLogin = Loadable(lazy(() => import('pages/authentication/urunyonetimi')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/uruntakibi')));
const RAPORTOTAL = Loadable(lazy(() => import('pages/authentication/giriscikis')));
const EXSTOCKENTRY = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/ExcelOperation/StockEntryExcel')));
const EXTRANSFERSENDING = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/ExcelOperation/TransferSendingExcel')));
const EXTRANSFERCOMPILATION = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/ExcelOperation/TransferCompilationExcel')));
const EXCONSIGNMENTSENDING = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/ExcelOperation/ConsignmentSendingExcel')));
const EXCONSIGNMENTCOMPILATION = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/ExcelOperation/TransferCompilationExcel')));
const DATABASEIM = Loadable(lazy(() => import('pages/authentication/databsegiris')));
const ITEMIM = Loadable(lazy(() => import('pages/authentication/itemgiris')));
const LOGIN = Loadable(lazy(() => import('Screens/LoginAndAuthantication/Login/Pages/Login')));
const ITEMEDIT = Loadable(lazy(() => import('pages/authentication/Duzenleme')));
const EXDIRECTSELLING = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/ExcelOperation/DirectSellingExcel')));
const MUHASEBEMENU = Loadable(lazy(() => import('pages/authentication/MuhasebeMenu')));
const BASTOCKENTRY = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/BarcodeOperation/StockEntry')));
const BATRANSFERSENDING = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/BarcodeOperation/TransferSending')));
const BATRANSFERCOMPILATION = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/BarcodeOperation/TransferCompilation')));
const BACONSIGHMENTSENDING = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/BarcodeOperation/ConsignmentSending')));
const BACONSIGNMENTCOMPILATION = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/BarcodeOperation/ConsignmentCompilation')));
const DEPORAPORLAMA = Loadable(lazy(() => import('../Screens/CentralWarehouseAndLogistics/Pages/Reports')));
const DEPOFONKSIYON = Loadable(lazy(() => import('../Screens/CentralWarehouseAndLogistics/Pages/Operation')));
const ONAYLAMA = Loadable(lazy(() => import('../Screens/AdminastrativeOffice/Pages/SaleCheck')));
const EXSALECHECH = Loadable(lazy(() => import('Screens/CentralWarehouseAndLogistics/Pages/ExcelOperation/SaleChechExcel')));
const DUZENLEMEVEKAYIT = Loadable(lazy(() => import('Screens/AdminastrativeOffice/Pages/ProductAndOperationEditing.js')));
const ADMINSTRATIVERAPOR = Loadable(lazy(() => import('Screens/AdminastrativeOffice/Pages/Reports')));
const ALLSTOCKREPORT = Loadable(lazy(() => import('Screens/AdminastrativeOffice/Pages/AllStockReport')));
const COMMONWAREHOUSEANDREGIONALOPERATION = Loadable(lazy(() => import('Screens/CommonWarehouseAndRegional/Pages/Operation/Operation')));
const COMMONBATRANSFERCOMPILATION = Loadable(lazy(() => import('Screens/CommonWarehouseAndRegional/Pages/Operation/TransferCompilation')));
const COMMONBACONSIGNMENTCOMPILATION = Loadable(lazy(() => import('Screens/CommonWarehouseAndRegional/Pages/Operation/ConsignmentCompilation')));
const COMMONBATRANSFERSENDING = Loadable(lazy(() => import('Screens/CommonWarehouseAndRegional/Pages/Operation/TransferSending')));
const COMMONBACONSIGNMENTSENDING = Loadable(lazy(() => import('Screens/CommonWarehouseAndRegional/Pages/Operation/ConsignmentSending')));
const COMMONEXPIRATIONPAGE = Loadable(lazy(() => import('Screens/CommonWarehouseAndRegional/Pages/Report/Expirations')));
const COMMONALLTABLE = Loadable(lazy(() => import('Screens/CommonWarehouseAndRegional/Pages/Report/AllStockReport')));
const TotalSaleManagement = Loadable(lazy(() => import('Screens/Management/Pages/TotalSale')));
const TotalStockManagement = Loadable(lazy(() => import('Screens/Management/Pages/ActiveStock')));
const WaitingListManagement = Loadable(lazy(() => import('Screens/Management/Pages/WaitingList')));
const RegionalSaleManagement = Loadable(lazy(() => import('Screens/Management/Pages/RegionalSales')));
const GraphicalReportsManagement = Loadable(lazy(() => import('Screens/Management/Pages/GraphicalReports')));
const ADDUSER = Loadable(lazy(() => import('Screens/LoginAndAuthantication/Login/Pages/AddSampleUser')));
const UPDATEUSER = Loadable(lazy(() => import('Screens/LoginAndAuthantication/Login/Pages/EditUser')));





const TokenControlledMainLayout = withTokenControl(MainLayout);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <MANAGEMENTINDEX />
        },
        {
            path: 'color',
            element: <Color />
        },
        {
            path: 'management',
            children: [
                {
                    path: 'index',
                    element: <MANAGEMENTINDEX />
                },
            ]
        },
        {
            path: 'management/totalsale',
            element: <TotalSaleManagement />
        },
        {
            path: 'management/waitinglist',
            element: <WaitingListManagement />
        },
        {
            path: 'management/activestock',
            element: <TotalStockManagement />
        },
        {
            path: 'management/regionalsale',
            element: <RegionalSaleManagement />
        },
        {
            path: 'management/graphicalreports',
            element: <GraphicalReportsManagement />
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'shadow',
            element: <Shadow />
        },
        {
            path: 'typography',
            element: <Typography />
        },
        {
            path: 'icons/ant',
            element: <AntIcons />
        },
        {
            path: 'depo',
            element: <AuthLogin />
        },
        {
            path: 'raporlama',
            element: <AuthRegister />
        },
        {
            path: 'yardim',
            element: <YardimPage />
        },
        {
            path: 'depo/fonksiyon/exstockentry',
            element: <EXSTOCKENTRY />
        },
        {
            path: 'depo/fonksiyon/extransfersending',
            element: <EXTRANSFERSENDING />
        },
        {
            path: 'depo/fonksiyon/extransfercompilation',
            element: <EXTRANSFERCOMPILATION />
        },
        {
            path: 'depo/fonksiyon/exconsignmentsending',
            element: <EXCONSIGNMENTSENDING />
        },
        {
            path: 'depo/fonksiyon/exconsignmentcompilation',
            element: <EXCONSIGNMENTCOMPILATION />
        },
        {
            path: 'raporlamatotal',
            element: <RAPORTOTAL />
        },
        {
            path: 'databaseimport',
            element: <DATABASEIM />
        },
        {
            path: 'itemgiris',
            element: <ITEMIM />
        },
        {
            path:'depo/fonksiyon/exdirectselling',
            element: <EXDIRECTSELLING />
        },
        {
            path: 'depo/fonksiyon/exsalechech',
            element: <EXSALECHECH />
        },
        {
            path: 'login',
            element: <LOGIN />
        },
        {
            path: 'editpage',
            element: <ITEMEDIT />
        },
        {
            path: 'muhasebesekmesi',
            element: <MUHASEBEMENU />
        },
        {
            path: 'depo/fonksiyon/stockentry',
            element: <BASTOCKENTRY />
        },
        {
            path: 'depo/fonksiyon/batransfersending',
            element: <BATRANSFERSENDING />
        },
        {
            path: 'depo/fonksiyon/batransfercompilation',
            element: <BATRANSFERCOMPILATION />
        },
        {
            path: 'depo/fonksiyon/baconsignmentsending',
            element: <BACONSIGHMENTSENDING />
        },
        {
            path: 'depo/fonksiyon/baconsignmententry',
            element: <BACONSIGNMENTCOMPILATION />
        },
        {
            path: 'depo/raporlama',
            element: <DEPORAPORLAMA />
        },
        {
            path: 'depo/fonksiyon',
            element: <DEPOFONKSIYON />
        }
        ,
        {
            path: 'yonetim/onaylama',
            element: <ONAYLAMA />
        },
        {
            path: 'duzenlemevekayit',
            element: <DUZENLEMEVEKAYIT />
        },
        {
            path: 'adminstrativereport',
            element: <ADMINSTRATIVERAPOR />
        },
        {
            path: 'giriscikis',
            element: <ALLSTOCKREPORT />
        },
        {
            path: 'bolge/operasyon',
            element: <COMMONWAREHOUSEANDREGIONALOPERATION/>
        },
        {
            path: 'bolge/operasyon/baconsignmentsending',
            element: <COMMONBACONSIGNMENTSENDING/>
        },
        {
            path: 'bolge/operasyon/batransfersending',
            element: <COMMONBATRANSFERSENDING />
        },        {
            path: 'bolge/operasyon/batransfercompilation',
            element: <COMMONBATRANSFERCOMPILATION />
        },
        {
            path: 'bolge/operasyon/baconsignmentcompilation',
            element: <COMMONBACONSIGNMENTCOMPILATION/>
        },
        {
            path: 'bolge/expiration',
            element: <COMMONEXPIRATIONPAGE/>
        },
        {
            path: 'bolge/alltable',
            element: <COMMONALLTABLE/>
        },
        {
            path: 'addsampleuser',
            element: <ADDUSER/>
        },
        {
            path: 'edituser',
            element: <UPDATEUSER/>
        },


    ]
};

export default MainRoutes;
