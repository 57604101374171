// tokenService.js
const checkToken = () => {
    const token = localStorage.getItem('token');

    if (!token) {
        // Token yoksa, oturumu sonlandırma sayfasına yönlendir veya giriş sayfasına gönder
        window.location.replace('/login');
        return false; // Token geçerli değilse false döndür
    }

    // Token geçerliyse, isteği devam ettir
    return true;
};

export default checkToken;
