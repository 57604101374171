// withTokenControl.js
import React from 'react';
import checkToken from './tokenService';

const withTokenControl = (WrappedComponent) => {
    return (props) => {
        // Token kontrol işlemini gerçekleştir
        const isTokenValid = checkToken();

        // Eğer token geçerliyse, wrapped component'ı render et
        return isTokenValid ? <WrappedComponent {...props} /> : null;
    };
};

export default withTokenControl;
