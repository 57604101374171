// material-ui

import logo from 'assets/logo.jpeg';
/**
 * import { useTheme } from '@mui/material/styles';
 *
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return <img src={logo} alt="Mantis" width="100" />;
};

export default Logo;
