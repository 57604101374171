// material-ui
import { Box, IconButton, Link, useMediaQuery } from '@mui/material';
import { GithubOutlined } from '@ant-design/icons';
import { PlusCircleTwoTone } from '@ant-design/icons';

// project import
import Search from './Search';
import Profile from './Profile';
import Notification from './bizim';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <>
            {!matchesXs && <Search />}
            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

            <IconButton
                component={Link}
                href="http://192.168.1.179:3000/dashboard/default"
                target="_blank"
                disableRipple
                color="secondary"
                title="Yeni Sayfa Aç"
                sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
            >
                <PlusCircleTwoTone twoToneColor="#46a2ed" />
            </IconButton>

            <Notification />
            {!matchesXs && <Profile />}
            {matchesXs && <MobileSection />}
        </>
    );
};

export default HeaderContent;
