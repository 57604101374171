/* eslint-disable */
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from 'Screens/LoginAndAuthantication/Login/Pages/AuthContext';

const PrivateRoute = ({ condition, redirectPath, children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        if (!isAuthenticated) {
            if (location.pathname !== '/login') {
                navigate(redirectPath);
            }
        } else {
            if (location.pathname === '/login') {
                navigate('/');
            }
        }
    }, [isAuthenticated, location.pathname, navigate, redirectPath]);

    if (!isAuthenticated && location.pathname !== '/login') {
        return null;
    }

    return children;
};

export default PrivateRoute;
