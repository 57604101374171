// assets
import { ChromeOutlined, QuestionOutlined, FileSyncOutlined, AccountBookOutlined } from '@ant-design/icons';

// icons
const icons = {
    ChromeOutlined,
    QuestionOutlined,
    FileSyncOutlined,
    AccountBookOutlined
};

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
    id: 'support',
    title: 'Muhasebe Otomasyon Modülü',
    type: 'group',
    children: [
        {
            id: 'sample-page',
            title: 'Logo Alım Satım İşlemleri',
            type: 'item',
            url: '/sample-page',
            icon: icons.AccountBookOutlined
        },
        {
            id: 'documentation',
            title: 'Logo Stok Güncelleme',
            type: 'item',
            url: '/sample-page',
            icon: icons.FileSyncOutlined
        }
    ]
};

export default support;
