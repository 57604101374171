/*eslint-disable*/
// project import
import management from './Management';
import dashboard from './dashboard';
import utilities from './utilities';
import support from './support';
import kayit from './CentralWarehouseAndLogistics';
import CommonWarehouseAndRegional from './CommonWarehouseAndRegional';
import AdminstrativeOffice from './AdminstrativeOffice';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [management,AdminstrativeOffice, kayit, CommonWarehouseAndRegional]
};

export default menuItems;
