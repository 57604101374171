// assets
import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    LoadingOutlined,
    DatabaseTwoTone,
    SnippetsOutlined,
    PieChartOutlined,
    HistoryOutlined,
    ReconciliationOutlined
} from '@ant-design/icons';

// icons
const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    AppstoreAddOutlined,
    DatabaseTwoTone,
    SnippetsOutlined,
    PieChartOutlined,
    HistoryOutlined,
    ReconciliationOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Raporlar',
    type: 'group',
    children: [
        {
            id: 'util-typography',
            title: 'Stok Raporları',
            type: 'item',
            url: '/typography',
            icon: icons.SnippetsOutlined
        },
        {
            id: 'util-color',
            title: 'Tarih Bazlı Raporlamalar',
            type: 'item',
            url: '/color',
            icon: icons.HistoryOutlined
        },
        {
            id: 'ant-icons',
            title: 'Özelleştirilmiş Raporlar',
            type: 'item',
            url: '/icons/ant',
            icon: icons.ReconciliationOutlined,
            breadcrumbs: false
        }
    ]
};

export default utilities;
