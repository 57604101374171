/*eslint-disable*/
import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    LoadingOutlined,
    DatabaseTwoTone,
    SnippetsOutlined,
    PieChartOutlined,
    HistoryOutlined,
    ReconciliationOutlined,
    ApartmentOutlined,
    FileSearchOutlined
} from '@ant-design/icons';


// icons
const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    AppstoreAddOutlined,
    DatabaseTwoTone,
    SnippetsOutlined,
    PieChartOutlined,
    HistoryOutlined,
    ReconciliationOutlined,
    ApartmentOutlined,
    FileSearchOutlined
};

import SaveIcon from '@mui/icons-material/Save';

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const pages = {   
    id: 'pagesx',
    title: 'Depo ve Lojistik',
    type: 'group',
    children: [
      {
            id: 'pagessda',
            title: 'Barkod Otomasyon Sistemi',
            type: 'item',
            url: '/depo/fonksiyon',
            icon: icons.BarcodeOutlined
        },
        {
            id: 'pagessdb',
            title: 'Raporlar',
            type: 'item',
            url: '/depo/raporlama',
            icon: icons.FileSearchOutlined
        }
    
    ]
};

export default pages;
