/*eslint-disable*/
import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    LoadingOutlined,
    DatabaseTwoTone,
    SnippetsOutlined,
    PieChartOutlined,
    HistoryOutlined,
    ReconciliationOutlined,
    ApartmentOutlined,FileSearchOutlined
} from '@ant-design/icons';

// icons
const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    AppstoreAddOutlined,
    DatabaseTwoTone,
    SnippetsOutlined,
    PieChartOutlined,
    HistoryOutlined,
    ReconciliationOutlined,
    ApartmentOutlined,
    FileSearchOutlined
};

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SaveIcon from "@mui/icons-material/Save";

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const pages = {
    id: 'planlayiciyönetimpage',
    title: 'Planlayıcı Yönetim',
    type: 'group',
    children: [
        {
            id: 'planlayiciyönetimpage1',
            title: 'Onaylama',
            type: 'item',
            url: '/yonetim/onaylama',
            icon: AccountBalanceIcon
        },        {
            id: 'planlayiciyönetimpage3',
            title: 'Rapor',
            type: 'item',
            url: '/adminstrativereport',
            icon: FileSearchOutlined
        },        {
            id: 'pagessd',
            title: 'Giris Ve Çıkış Rapor',
            type: 'item',
            url: '/giriscikis',
            icon: icons.BarcodeOutlined
        },{
            id: 'planlayiciyönetimpage2',
            title: 'Düzenleme ve Kayıt İşlemleri',
            type: 'item',
            url: '/duzenlemevekayit',
            icon: SaveIcon
        }

    ]
};

export default pages;
