/*eslint-disable*/
import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    LoadingOutlined,
    DatabaseTwoTone,
    SnippetsOutlined,
    PieChartOutlined,
    HistoryOutlined,
    ReconciliationOutlined,
    ApartmentOutlined
} from '@ant-design/icons';

import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';

// icons
const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    AppstoreAddOutlined,
    DatabaseTwoTone,
    SnippetsOutlined,
    PieChartOutlined,
    HistoryOutlined,
    ReconciliationOutlined,
    ApartmentOutlined,
    StackedLineChartIcon
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const management = {
    id: 'management',
    title: 'Yönetim',
    type: 'group',
    children: [
        {
            id: 'management',
            title: 'Aylık Özet',
            type: 'item',
            url: '/management/index',
            icon: icons.ApartmentOutlined
        },
        {
            id: 'managementgraph',
            title: 'Grafik Raporları',
            type: 'item',
            url: '/management/graphicalreports',
            icon: icons.StackedLineChartIcon
        }

    ]
};

export default management;
