// context/AuthContext.js
/* eslint-disable*/
// context/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
    const [userRole, setUserRole] = useState(null);
    const navigate = useNavigate();

    const login = (token) => {
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        const decodedToken = jwtDecode(token);
        setUserRole(decodedToken.role);

        // Navigate after setting the user role
        if (decodedToken.role === 'ROLE_ADMIN') {
            navigate('/admin');
        } else if (decodedToken.role === 'ROLE_MANAGEMENT') {
            navigate('/management');
        } else if (decodedToken.role === 'ROLE_CENTRALWAREHOUSE') {
            navigate('/depo');
        } else {
            navigate('/');
        }
    };

    const logout = async () => {
        try {
            await fetch('https://api.atailac.com/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
            });
            localStorage.removeItem('token');
            setIsAuthenticated(false);
            setUserRole(null);
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    useEffect(() => {
        const handleStorageChange = () => {
            setIsAuthenticated(!!localStorage.getItem('token'));
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, userRole, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
