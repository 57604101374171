/*eslint-disable*/
import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    LoadingOutlined,
    DatabaseTwoTone,
    SnippetsOutlined,
    PieChartOutlined,
    HistoryOutlined,
    ReconciliationOutlined,
    ApartmentOutlined,
    FileSearchOutlined,

} from '@ant-design/icons';

// icons
const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    AppstoreAddOutlined,
    DatabaseTwoTone,
    SnippetsOutlined,
    PieChartOutlined,
    HistoryOutlined,
    ReconciliationOutlined,
    ApartmentOutlined,
    FileSearchOutlined,
    FireTruckOutlined
};

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SaveIcon from "@mui/icons-material/Save";
import {FireTruckOutlined} from "@mui/icons-material";

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const pages = {
    id: 'commonwarehouse',
    title: 'Bölgesel Depolar',
    type: 'group',
    children: [
        {
            id: 'commonwarehouse1',
            title: 'Stok Takibi',
            type: 'item',
            url: 'bolge/alltable',
            icon: AccountBalanceIcon
        }, {
            id: 'commonwarehouse4',
            title: 'Miad Takibi',
            type: 'item',
            url: 'bolge/expiration',
            icon: FileSearchOutlined
        },       {
            id: 'commonwarehouse2',
            title: 'Lojistik Hareketleri',
            type: 'item',
            url: '/abc',
            icon: FireTruckOutlined
        },{
            id: 'commonwarehouse3',
            title: 'Barkod Otomasyon Sistemi',
            type: 'item',
            url: '/bolge/operasyon',
            icon: BarcodeOutlined
        },


    ]
};

export default pages;
