/*eslint-disable*/
import React from 'react';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import PrivateRoute from 'PrivateRoute';
import MainRoutes from './routes';
import {AuthProvider, useAuth} from 'Screens/LoginAndAuthantication/Login/Pages/AuthContext';
import {useNavigate} from "react-router-dom";

const AppContent = () => {
    const { isAuthenticated } = useAuth();

    return (
        <ThemeCustomization>
            <ScrollTop>
                <PrivateRoute
                    condition={isAuthenticated}
                    redirectPath="/login"
                >
                    <MainRoutes />
                </PrivateRoute>
            </ScrollTop>
        </ThemeCustomization>
    );
};

const App = () => (
    <AuthProvider>
        <AppContent />
    </AuthProvider>
);

export default App;